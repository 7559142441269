import React, { useState } from "react"
import styled from "styled-components"
import { DateRangePicker } from "react-dates"
import { injectIntl } from "gatsby-plugin-intl"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import moment from "moment"
import { getDistributor } from "../distributor"
import "moment/locale/fi"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import { TranslationContext } from "../translate"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 769px) {
        position: relative;
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #fff;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
`

const Button = styled.button`
    appearance: none;
    border: none;
    background: #6d887d;
    color: #ffffff;
    font-weight: normal;
    text-transform: uppercase;
    display: block;
    align-self: stretch;
    padding: 0.5em 1em;
    cursor: pointer;
    &:hover {
        background: #42564e;
    }
    &[disabled] {
        background: #d3cec0;
    }
`

const onBookClick = async (startDate, endDate) => {
    const distributor = await getDistributor()
    distributor.setStartDate(startDate.toDate())
    distributor.setEndDate(endDate.toDate())
    //distributor.showRooms()
    distributor.open()
}


const DatePicker = ({intl}) => {
  const breakpoints = useBreakpoint()
  const isDesktop = !breakpoints.sm
  const isFinnish = intl.locale === "fi"
  moment.locale(isFinnish ? "fi" : "en")
  const minDate = moment.max([moment(), moment("2021-08-03T12:00:00")])
  const [focusedInput, setFocusedInput] = useState(null)
  const [startDate, setStartDate] = useState(minDate.clone())
  const [endDate, setEndDate] = useState(startDate ? startDate.clone().add(1, 'days') : null)
  const isValid = startDate && startDate.isValid() && endDate && endDate.isValid()
  return <TranslationContext.Consumer>{(t) =>
  <Wrapper>
    <Box>
      <DateRangePicker
        startDate={startDate}
        startDateId="date-picker-start-date-id"
        startDatePlaceholderText={isFinnish ? "Alkaa" : t("Start date")}
        endDate={endDate}
        endDateId="date-picker-end-date-id"
        endDatePlaceholderText={isFinnish ? "Päättyy" : t("End date")}
        onDatesChange={({ startDate, endDate }) => {setStartDate(startDate); setEndDate(endDate)}}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        showDefaultInputIcon={true}
        noBorder={true}
        block={true}
        numberOfMonths={2}
        orientation={isDesktop ? "horizontal" : "vertical"}
        small={!isDesktop}
        minimumNights={1}
        minDate={minDate}
        isDayBlocked={(day) => day.isBefore(minDate.clone().subtract(1, 'days'))} />
    <Button disabled={!isValid} onClick={() => onBookClick(startDate, endDate)}>
        {isFinnish ? "Etsi ja varaa" : t("Book")}
    </Button>
    </Box>
  </Wrapper>
  }</TranslationContext.Consumer>
}

export default injectIntl(DatePicker)
